import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { H4, P } from "../../../styled/Text"
import { StyledButton } from "../../../styled/Button"
import { StyledCard } from "../../../styled/Card"
import { StyledLink } from "../../../styled/Link"
import { StyledRow } from "../../../styled/Row"
import { StyledColumn } from "../../../styled/Column"

export { StyledRow as Row } from "../../../styled/Row"
export { StyledLink } from "../../../styled/Link"

export const Card = styled(StyledCard)`
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`p-5` : null)}
  ${({ horizontal }) => (horizontal ? tw`flex p-2 text-left` : null)}
`
export const Frame = styled.figcaption`
  ${({ horizontal }) => (horizontal ? tw`p-2 px-3 flex-1` : null)}
`
export const Image = styled.img`
  ${tw`h-40 block w-full mb-3 rounded-lg`}
  object-fit: cover;
`

export const Title = styled(H4)`
  ${tw`text-grey-dark mt-3 mb-2`}
  ${({ size, horizontal }) => (size === "small" || horizontal ? tw`lg:text-base text-base mt-2 mb-12` : null)}
  ${({ size }) => (size === "medium" ? tw`lg:text-md text-md mb-16` : null)}
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`lg:text-lg text-lg` : null)}
  ${({ horizontal }) => (horizontal ? tw`block m-0 mb-2` : null)}
`
export const Content = styled(P)`
  ${tw`text-grey-mid leading-tight`}
  ${({ size, horizontal }) => (size === "small" || horizontal ? tw`mb-3 lg:text-xs text-xs` : null)}
  ${({ size }) => (size === "medium" ? tw`mb-4 lg:text-sm text-sm` : null)}
  ${({ size, horizontal }) => (size === "large" && !horizontal ? tw`mb-5 lg:text-base text-base` : null)}
`
export const DateTime = styled.time`
  ${tw`block font-bold text-xxs leading-none uppercase`}
  ${({ horizontal }) => (horizontal ? tw`text-grey-mid mb-2` : tw`text-green`)}
`
export const Status = tw(P)`block text-xxs text-pink mb-1`
export const Location = tw.div`flex items-center mt-4 md:mt-8 mb-4 md:mb-8 text-xs text-grey-light leading-normal`
export const LocationText = tw.div`ml-2`
export const MembersColumn = tw(StyledColumn)`w-30 md:w-2/3 lg:w-30`
export const RegistrationColumn = tw(StyledColumn)`flex-1 md:w-full lg:w-auto`
export const Members = tw.div`relative flex mr-4 mt-4`
export const Member = styled.div`
  ${tw`absolute left-0 z-4`}
  ${({ index }) =>
    !index
      ? tw`relative`
      : index === 1
      ? tw`transform translate-x-35-pc z-3`
      : index === 2
      ? tw`transform translate-x-70-pc z-2`
      : index === 3
      ? tw`transform translate-x-105-pc z-1`
      : null}
`
export const RegisterTitle = tw(P)`mt-4 text-grey-mid uppercase font-bold text-xs`
export const RegisteredIndicator = tw(P)`uppercase text-pink font-bold text-sm mt-4`
export const RegisteredAttendees = tw(RegisteredIndicator)`text-grey-mid text-xxs mt-0`
export const RegisterRow = tw(StyledRow)`mt-4`
export const Button = styled(StyledButton)`
  ${({ horizontal }) => (horizontal ? tw`mb-0 float-right` : tw`flex`)}
`
export const CardLink = styled(Link)`
  ${({ size }) => (size === "full" ? tw`block` : tw`inline-block`)}
`
export const WishlistButton = styled.button`
  ${tw`absolute top-8 right-8 focus:outline-none text-pink z-2`}
`
