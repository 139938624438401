import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import { StyledTab } from "../../styled/Tab"

export { StyledColumn as Column } from "../../styled/Column"

export const Section = tw.section`block mb-8`
export const List = tw.div`flex flex-wrap items-center md:justify-between`
export const Tab = tw(StyledTab)`w-full md:w-auto md:mx-3 flex-1 md:flex-initial`
export const Content = tw.div`flex flex-col justify-center items-center pt-8 md:w-full`
export const Item = styled.div`
  ${({ active }) => (active ? tw`block w-full` : tw`hidden`)}
`
export const StyledLink = tw(Link)`hidden md:flex items-center justify-end font-medium text-xs text-green underline hover:no-underline`
export const LinkText = tw.span`underline hover:no-underline inline-block mr-2`
