import React from "react"

import { withSavedItems } from "./withSavedItems"
import { SavedItemCard } from "./SavedItemCard"
import { Tabs } from "../../Tabs/Tabs"
import { Container, Responsive, Title, Subtitle, Row, Column, P } from "./SavedItems.styled"
import SavedItemEmpty from "./SavedItemEmpty"

export const SavedItems = withSavedItems(
  ({ mentors, events, videos, podcasts }: Props): JSX.Element => (
    <>
      <Container topSpacing={`md`} width={`xl`}>
        <Title>Saved Items</Title>
        <Subtitle></Subtitle>
      </Container>

      <Container spacing={`xl`} collapse={`<md`} width={`xl`}>
        <Tabs
          // link={"/events"}
          // linkText={additionalAll}
          items={[
            {
              title: `${mentors?.length > 0 ? "Mentors (" + mentors?.length + ")" : "Mentors"}`,
              content: (
                <Container condense={`<md`}>
                  <Row wrap>
                    {mentors?.length ? (
                      mentors?.map(({ title, content, timestamp, image, link, ...item }) => {
                        return (
                          <Column key={item.id} width={"md:w-1/3"}>
                            <Responsive screen={`md>`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                              />
                            </Responsive>
                            <Responsive screen={`<md`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                              />
                            </Responsive>
                          </Column>
                        )
                      })
                    ) : (
                      <SavedItemEmpty type={"mentors"}></SavedItemEmpty>
                    )}
                  </Row>
                </Container>
              ),
            },
            {
              title: `${events?.length > 0 ? "Events (" + events?.length + ")" : "Events"}`,
              content: (
                <Container condense={`<md`}>
                  <Row wrap>
                    {events?.length ? (
                      events?.map(({ title, content, timestamp, image, link, ...item }) => {
                        return (
                          <Column key={item.id} width={"md:w-1/3"}>
                            <Responsive screen={`md>`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                              />
                            </Responsive>
                            <Responsive screen={`<md`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                              />
                            </Responsive>
                          </Column>
                        )
                      })
                    ) : (
                      <SavedItemEmpty type={"events"}></SavedItemEmpty>
                    )}
                  </Row>
                </Container>
              ),
            },
            {
              title: `${videos?.length > 0 ? "Videos (" + videos?.length + ")" : "Videos"}`,
              content: (
                <Container condense={`<md`}>
                  <Row wrap>
                    {videos?.length ? (
                      videos?.map(({ title, content, timestamp, image, link, ...item }) => {
                        return (
                          <Column key={item.id} width={"md:w-1/3"}>
                            <Responsive screen={`md>`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                                type="video"
                              />
                            </Responsive>
                            <Responsive screen={`<md`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                                type="video"
                              />
                            </Responsive>
                          </Column>
                        )
                      })
                    ) : (
                      <SavedItemEmpty type={"videos"}></SavedItemEmpty>
                    )}
                  </Row>
                </Container>
              ),
            },
            {
              title: `${podcasts?.length > 0 ? "Podcasts (" + podcasts?.length + ")" : "Podcasts"}`,
              content: (
                <Container condense={`<md`}>
                  <Row wrap>
                    {podcasts?.length ? (
                      podcasts?.map(({ title, content, timestamp, image, link, ...item }) => {
                        return (
                          <Column key={item.id} width={"md:w-1/3"}>
                            <Responsive screen={`md>`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                              />
                            </Responsive>
                            <Responsive screen={`<md`}>
                              <SavedItemCard
                                id={item.id}
                                size={`full`}
                                content={content}
                                date={new Date(timestamp).toString()}
                                image={image}
                                link={link}
                                title={title}
                              />
                            </Responsive>
                          </Column>
                        )
                      })
                    ) : (
                      <SavedItemEmpty type={"podcasts"}></SavedItemEmpty>
                    )}
                  </Row>
                </Container>
              ),
            },
          ]}
        />
      </Container>
    </>
  )
)

export interface Props {
  mentors?: Array<any>
  events?: Array<any>
  videos?: Array<any>
  podcasts?: Array<any>
}
