import React from "react"

import { withTabs } from "./withTabs"
import { Column, Section, List, Tab, Content, Item, StyledLink, LinkText } from "./Tabs.styles"
import { Icon } from "../Icon/Icon"

export const Tabs = withTabs(
  ({ items = [], link, linkText, active, handleActive }: Props): JSX.Element => (
    <Section>
      <List>
        {link && linkText ? <Column width={`md:w-1/4`} /> : null}
        <Column position={`center`} width={link && linkText ? `md:w-1/2` : `w-full`}>
          {items?.map(({ title }, index) => (
            <Tab key={title?.toString()} onClick={() => handleActive(index)} active={index === active}>
              {title}
            </Tab>
          ))}
        </Column>
        {link && linkText ? (
          <Column position={`right`} width={`md:w-1/4`}>
            <StyledLink to={link}>
              <LinkText>{linkText}</LinkText> <Icon name={`chevronRight`} size={`xs`} />
            </StyledLink>
          </Column>
        ) : null}
      </List>
      <Content>
        {items?.map(({ content, title }, index) => (
          <Item key={title?.toString()} active={index === active}>
            {content}
          </Item>
        ))}
      </Content>
    </Section>
  )
)

export interface Props {
  items?: Array<{
    title: string
    content: any
  }>
  activeItem?: number
  link?: string
  linkText?: string
  active?: number
  handleActive?: (show: any) => void
}
