import React, { useMemo, useEffect, useState } from "react"

import { useCore } from "../../../hooks/useCore"
import { useSavedItems } from "../../../hooks/useSavedItems"

export const withSavedItems = Component =>
  React.memo(({ name = "SavedItems", ...props }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const { savedMentors, savedEvents, savedVideos, savedPodcasts  } = useSavedItems()

    Component.displayName = name
    return useMemo(() => <Component {...props} mentors={savedMentors} events={savedEvents} videos={savedVideos} podcasts={savedPodcasts}  />, [ savedMentors, savedEvents, savedVideos, savedPodcasts])
  })
