import * as React from "react"
import {Container, Button, Link} from "./SavedItemEmpty.styled"
export default ({type}): JSX.Element => {
  return <Container width={`md`}>
    
      Sorry, we can't find  saved {type}.
      <Button as={Link} to={`/${type}`} size={`large`} theme={`primary`} colour={`dark`}>
        Browse {type}
      </Button>
  </Container>
}

export interface Props {
  type?: string
}