import tw, { styled } from "twin.macro"

const styles = {
  state: {
    default: tw`border-grey-lighter hover:border-grey md:border-transparent`,
    active: tw`border-pink text-pink`,
  },
}

export const StyledTab = styled.button<{
  active: boolean
}>`
  ${tw`border-b-2 h-8 inline-block w-48 text-center uppercase text-xxs text-grey-mid tracking-wide leading-relaxed font-medium cursor-pointer focus:outline-none md:h-auto`}
  ${({ active }) => (active ? styles.state.active : styles.state.default)}
`
