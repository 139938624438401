import React from "react"

import { withSavedItemCard } from "./withSavedItemCard"
import { Icon } from "../../Icon/Icon"
import {
  Card,
  Row,
  Frame,
  Image,
  Title,
  Content,
  DateTime,
  Location,
  LocationText,
  RegistrationColumn,
  RegisteredAttendees,
  RegisterRow,
  WishlistButton,
  Button,
  CardLink,
} from "./SavedItemCard.styed"

export const SavedItemCard = withSavedItemCard(
  ({
    link,
    image,
    horizontal = false,
    date,
    size,
    title,
    content,
    location,
    registration,
    members,
    attending = false,
    type,
    existsInSavedItems,
    handleSavedItem,
    handleVideoItemClick,
  }: Props): JSX.Element =>
    type && type == "video" ? (
      <Card shadow={`medium`} size={size} horizontal={horizontal} hover>
        <WishlistButton onClick={handleSavedItem}>
          <Icon name={`heart`} size={`ml`} />
        </WishlistButton>
        <div onClick={handleVideoItemClick}>
          {image && <Image src={image} alt={title}/>}
          <Frame horizontal={horizontal}>
            <DateTime>{date}</DateTime>
            <Title size={size} horizontal={horizontal}>
              {title}
            </Title>
            {(size === `large` || size === `full`) && !horizontal ? (
              <Content size={size} horizontal={horizontal}>
                {content}
              </Content>
            ) : null}
            {(size === `large` || size === `full`) && location && (
              <Location>
                <Icon name={`pin`} size={`m`} colour={`grey-light`} />
                <LocationText>{location}</LocationText>
              </Location>
            )}

            <Row center={true} wrap>
              <RegistrationColumn position={`right`}>
                <RegisterRow center={!(registration?.spaces && registration?.enabled)}>
                  {members ? <RegisteredAttendees>{`${members.length} attendees`}</RegisteredAttendees> : null}
                  {!horizontal && (
                    <Button theme={`primary`} colour={`outline`} size={`medium`} horizontal={horizontal} disabled={attending}>
                      view
                    </Button>
                  )}
                </RegisterRow>
              </RegistrationColumn>
            </Row>
          </Frame>
        </div>
      </Card>
    ) : (
      <CardLink size={size} to={link}>
        <Card shadow={`medium`} size={size} horizontal={horizontal} hover>
          <WishlistButton active={existsInSavedItems} onClick={handleSavedItem}>
            <Icon name={`heart`} size={`ml`} />
          </WishlistButton>
          {image && <Image src={image} size={size} horizontal={horizontal} alt={title}/>}
          <Frame horizontal={horizontal}>
            <DateTime>{date}</DateTime>
            <Title size={size} horizontal={horizontal}>
              {title}
            </Title>
            {(size === `large` || size === `full`) && !horizontal ? (
              <Content size={size} horizontal={horizontal}>
                {content}
              </Content>
            ) : null}
            {(size === `large` || size === `full`) && location && (
              <Location>
                <Icon name={`pin`} size={`m`} colour={`grey-light`} />
                <LocationText>{location}</LocationText>
              </Location>
            )}

            <Row center={true} wrap>
              <RegistrationColumn position={`right`}>
                <RegisterRow center={!(registration?.spaces && registration?.enabled)}>
                  {members ? <RegisteredAttendees>{`${members.length} attendees`}</RegisteredAttendees> : null}
                  {!horizontal && (
                    <Button theme={`primary`} colour={`outline`} size={`medium`} horizontal={horizontal} disabled={attending}>
                      view
                    </Button>
                  )}
                </RegisterRow>
              </RegistrationColumn>
            </Row>
          </Frame>
        </Card>
      </CardLink>
    )
)

export interface Props {
  content?: string
  date?: Date
  horizontal?: boolean
  image?: any
  link?: string
  location?: string
  members?: Array<any>
  registration?: {
    enabled: boolean
    spaces: number
  }
  size?: string
  title?: string
  attending?: boolean
  handleSavedItem?: (any) => void
  existsInSavedItems?: boolean
  type?: string
  handleVideoItemClick?: () => void
}
