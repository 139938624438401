import React, { useMemo, useCallback } from "react"

import { useApp } from "../../../hooks/useApp"
import { useToggleSavedItemsProduct } from "../../../hooks/useSavedItems"
import { useTemplate } from "../../../hooks/useTemplate"
import { formatDistanceToNowStrict } from "date-fns"

export const withSavedItemCard = Component =>
  React.memo(({ name = "SavedItemCard", viponly, attending, date, loading, ...props }) => {
    const { ready, member, setActivePopup, setRedirectUrl, setActiveVideo } = useApp()
    const { event: template } = useTemplate()
    const past = new Date(date) && new Date(date).getTime() < new Date().getTime()
    const vip = member?.membership?.vip
    const upgrade = !member?.membership?.status || (viponly && !vip)

    const handleUpgrade = useCallback(
      show => {
        setActivePopup(show)
        setRedirectUrl(`${props?.link}/register`)
      },
      [setActivePopup, setRedirectUrl]
    )

    const { deleteFromSavedItems } = useToggleSavedItemsProduct()

    const handleSavedItem = e => {
      e.preventDefault()
      deleteFromSavedItems(props?.id)
    }

    const dateDistance = `Saved ${formatDistanceToNowStrict(new Date(date), {
      addSuffix: true,
    })}`

    const handleVideoItemClick = () => {
      const video = {
        title: props?.title,
        videoUrl: props?.link,
      }
      setActiveVideo(video)
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          viponly={viponly}
          date={dateDistance}
          past={past}
          template={template}
          attending={attending}
          upgrade={upgrade}
          handleUpgrade={handleUpgrade}
          loading={loading}
          handleSavedItem={handleSavedItem}
          handleVideoItemClick={handleVideoItemClick}
        />
      ),
      [attending, loading, member, ready]
    )
  })
