import tw from "twin.macro"

import { StyledColumn } from "../../../styled/Column"
import { StyledRow } from "../../../styled/Row"
import { H1, P } from "../../../styled/Text"

export { StyledContainer as Container } from "../../../styled/Container"
export { StyledResponsive as Responsive } from "../../../styled/Responsive"
export { P } from "../../../styled/Text"

export const Title = tw(H1)`text-center text-lg lg:text-3xl mb-4 md:mb-1 font-bold`
export const Subtitle = tw(P)`hidden md:block text-center mb-8 text-md`
export const Row = tw(StyledRow)`w-auto justify-start`
export const Column = tw(StyledColumn)`p-6 md:p-4`
