import { GatsbyImage } from "gatsby-plugin-image"
import tw from "twin.macro"

export { StyledButton } from "../../../styled/Button"
export { StyledColumn as Column } from "../../../styled/Column"
export { StyledContainer } from "../../../styled/Container"
export { StyledResponsive as Responsive } from "../../../styled/Responsive"
export { StyledLink as Link } from "../../../styled/Link"

import { StyledButton } from "../../../styled/Button"
import { StyledContainer } from "../../../styled/Container"

export const Container = tw(StyledContainer)`h-full bg-grey-lightest md:bg-transparent md:h-auto flex flex-col items-center`
export const Button = tw(StyledButton)`block w-full md:w-auto mt-8 mb-8 text-center`
